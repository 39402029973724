@import '../../../styles/customMediaQueries.css';
@import '../../../styles/marketplaceDefaults.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding-bottom: 6px;
  border: none;

  @media (--viewportMedium) {
    padding: 3px 0 29px 12px;
    padding: 4px 0 4px 12px;
  }
}

.list {
  margin: 0;
/*   background-color: blue; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px; /* Adjust the gap between items as needed */
  @media screen  and (max-width: 450px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.item {
  padding: 2px 0;
/*   background-color: red; */
  max-width: 240px;
  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;
  white-space: wrap;
  @media (--viewportMedium) {
    padding: 4px 0;
  }
}
