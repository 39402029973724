@import '../../../styles/customMediaQueries.css';
@import '../../../styles/marketplaceDefaults.css';
.root {
  outline: none;
}

.contentWrapper {
  margin-bottom: 0;
}

.buttonsWrapper {
  display: flex;
  margin: 0 0 0 0;
  padding: 1em 1em 1em 1em;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
}

.clearButton,
.cancelButton,
.submitButton {
  composes: textSmall from global;
  font-weight: var(--fontWeightMedium);

  /* Layout */
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    transition: width var(--transitionStyleButton);
  }
}

.clearButton {
  color: var(--successColor);

  /* Layout */
  margin: 0 auto 0 0;

  &:focus,
  &:hover {
    color: var(--successColor);
  }
}

.cancelButton {
  color: var(--colorGrey300);

  /* Layout */
  margin: 0;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.submitButton {
  @apply --marketplaceButtonStylesPrimary;

  /* Layout */
  margin: 0 0 0 19px;
  padding: 0.5em 1em;
  border-radius: 11px;
  &:focus,
  &:hover {
    transform: translateY(2px);
  }
}
