@import '../../../styles/customMediaQueries.css';
@import '../../../styles/marketplaceDefaults.css';

.noSearchResults {
  composes: h4 from global;

  /* Reserves 48px of vertical space */
  line-height: 24px;
  margin-top: 18px;
  margin-bottom: 18px;
  /*   background-color: red; */
  @media (--viewportMedium) {
    position: relative;
    top: 4em;
    margin-top: 8px;
    margin-bottom: 8px;
    padding-left: 16px;
    padding-bottom: 32px;
    color: #1c7881;
  }
  @media screen and (max-width: 560px) {
    padding-top: 180px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.resetAllFiltersButton {
  display: none;
  /* composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);


  display: inline;
  margin: 0;
  padding: 0;


  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
  } */
}
